<template>
  <div class="w-100">
    <iframe id="frameContent" class="w-100" type="text/html" />
  </div>
</template>

<script>
import config from "../config/app.config";
import { getToken } from "../bl/user.bl";
export default {
  name: "info",
  computed: {
    urlPath() {
      return `${config.URL}/swagger-ui.html`;
    },
  },
  mounted() {
    // document.getElementById("frameContent").src = this.urlPath;

    setTimeout(() => {
      const token = getToken();
      let xhr = new XMLHttpRequest();
      xhr.open("GET", this.urlPath);
      xhr.onreadystatechange = function handler() {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            console.log(this.response);
            // this.response is a Blob, because we set responseType above
            // var data_url = URL.createObjectURL(this.response);
            let iframe = document.getElementById("frameContent");
            // iframe.src = data_url;
            // var content =
            //   iframe.contentWindow ||
            //   iframe.contentDocument.document ||
            //   iframe.contentDocument;
            // console.log(this.response.documentElement.innerHTML);

            var content = iframe.document;
            if (iframe.contentDocument) content = iframe.contentDocument;
            else if (iframe.contentWindow)
              content = iframe.contentWindow.document;
            console.log(content);
            content.open();
            content.write(this.response.documentElement.innerHTML);
            content.close();
          } else {
            console.error("no pdf :(");
          }
        }
      };
      //xhr.responseType = "arraybuffer";
      xhr.responseType = "document";
      //xhr.responseType = "text/html";
      // xhr.responseType = "blob";
      xhr.setRequestHeader("Authorization", "Bearer " + getToken());
      xhr.send();
    }, 1000);
  },
};
</script>

<style></style>
